.Carouse_head {
    height: 800px;
}
.home_div .container{
    display: block !important;
}


.boxed-btn4 {
    text-align: center !important;
    background: #FF4A52;
    color: #fff;
    display: inline-block;
    padding: 13px 39px;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 500;
    border: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    text-transform: capitalize;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    cursor: pointer;
}

.where_togo_area {
    background: #040E27;
    padding: 50px 0;
}
.sec{
    background: #432323;
    padding: 50px 0;
}
.sec .form_area h3{
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
}

.where_togo_area .form_area h3 {
    font-size: 24px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
}

.h4_name {
    /* display: inline; */
    padding-bottom: 5px;
    font-size: 30px;
    color: #a02829;
    text-align: center;
    font-family: montserratregular;
    font-weight: 700;
}

.sets-us {
    
    margin: 20px auto 0;
    padding: 30px;
    /* width: 300px; */
    /* min-height: 300px; */
    background: #fff;
    text-align: center;
    box-shadow: 0 3px 8px 0 #d2cece;
    border-radius: 20px;
}

.sets-us img {
    width: 150px;
    vertical-align: middle;
}
.sets-us p{
    color: #6E6E6E
}
.find_your_special{
    padding-top: 20px;
}

.find_your_special .col-md-4 {

    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
}
@media (min-width: 768px){
.col-md-4 {
    
    width: 100% !important;
    max-width:100% !important
}}

@media (min-width: 992px) {
    .find_your_special .col-md-4 {
        width: 33.33333333% !important;
        float: left;
    }
}

@media (min-width: 0px) {
    .Carouse_head {
        height: 340px;
    }

    .subbtn {
        padding-top: 10px;
    }
}

@media (min-width: 400px) {
    .Carouse_head {
        height: 500px;
    }

    .subbtn {
        padding-top: 10px;
    }
}

@media (min-width: 728px) {
    .Carouse_head {
        height: 750px;
    }
}



/* ------------------------------- */

  .services-block-three{
    margin-bottom:30px;
  }
  .services-block-three i {
      font-size: 32px;
  }
  .services-block-three>a {
      display: block;
      border: 2px solid #d5d5d5;
      border-radius: 4px;
      text-align: center;
      background: #fff;
      padding: 20px;
      position: relative;
    margin-bottom:30px;
  
  }
  .services-block-three>a:before {
      display: block;
      content: "";
      width: 9%;
      height: 17%;
      position: absolute;
      bottom: -2px;
      right: -2px;
      border-bottom: 2px solid #03a9f5;
      border-right: 2px solid #03a9f5;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
  }
  .services-block-three>a:after {
      display: block;
      content: "";
      width: 9%;
      height: 17%;
      position: absolute;
      top: -2px;
      left: -2px;
      border-top: 2px solid #03a9f5;
      border-left: 2px solid #03a9f5;
      transition: all 0.5s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
  }
  .padding-15px-bottom {
      padding-bottom: 15px;
  }
  .services-block-three h4 {
      color: #6f6f6f;
      font-size: 14px;
      margin-bottom: 10px;
      font-weight: 600;
  }
  .services-block-three p {
      margin-bottom: 0;
    color: #757575;
  }
  .services-block-three>a:hover {
      opacity: 1;
      border-color: #d5d5d5;
  }
  .services-block-three a {
      color: #fdfdfd00 !important;
      text-decoration: none;
  }
  
  .services-block-three>a:hover:before, .services-block-three>a:hover:after {
      width: 95%;
      height: 90%;
  }