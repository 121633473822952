.mb_5 {
    margin-bottom: 3rem !important;
    /* top: 500px; */
}

.border {
    border-color: #e2e8f0 !important;
    position: relative !important;
    border-radius: 0.25rem !important;
    border: 1px solid #dee2e6 !important;
    margin-right: 0;
    margin-left: 0;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
styles.star_rating{
    display: block; 
    position: absolute !important;
}

.text_center {
    text-align: center !important;
}
.h_150px, .size_150px {
    height: 150px;
}
.w_150px, .size_150px {
    width: 150px;
}
.pb_1, .py_1 {
    padding-bottom: 0.25rem!important;
}
.w_25 {
    width: 25%!important;
}
.fw_400 {
    font-weight: 900 !important;
}
.img_fit {
    max-height: 100%;
    width: 100%;
    object-fit: cover;
}
.mw_100 {
    /* max-width: 100%!important; */
}
.rounded_circle {
    border-radius: 100%!important;
}
img {
    vertical-align: middle;
    border-style: none;
}
.position_static {
    position: static!important;
}
.align_items_center {
    align-items: center!important;
}
/* .d_flex {
    display: flex!important;
} */
.p_3 {
    padding: 1rem!important;
}
.flex_grow_1 {
    flex-grow: 1!important;
}
.fs_18 {
    font-size: 1.125rem !important;
}
.fw_600 {
    font-weight: 600 !important;
}
.text_truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.mb_1, .my_1 {
    margin-bottom: 0.25rem!important;
}
.h6, h6 {
    font-size: 1rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.row {
    background-color: #ffff;
    justify-content: space-around;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.fs_12 {
    font-size: 0.75rem !important;
}
.mb_2, .my_2 {
    margin-bottom: 0.5rem!important;
}
.opacity_60 {
    opacity: 0.6 !important;
}
.text_primary {
    color:#FD2C79 !important;
}
.opacity_70 {
    opacity: 0.7 !important;
}
.w_100 {
    width: 100%!important;
}
table {
    border-collapse: collapse;
}
.d_block {
    display: block!important;
}
.absolute_top_right {
    position: absolute;
    top: 0;
    right: 0;
}
.fs_10 {
    font-size: 0.625rem !important;
}
.px_4 {
    padding-left: 1.5rem!important;
}
.pb_3, .py_3 {
    padding-bottom: 1rem!important;
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge_inline {
    width: auto;
}
.col_4{
    width: 33.33333333% !important;
}
.col_8{
    width: 66.66666667% !important;
}
.h_150px, .size_150px {
    width: 150px;
    height: 150px;
}
.img_fit {
    max-height: 100%;
    width: 100%;
    object-fit: cover;
}

.text_left {
    text-align: left!important;
}
.pt_3, .py_3 {
    padding-top: 1rem!important;
}
.text_center {
    text-align: center!important;
}
.gutter_5 {
    margin-right: -5px;
    margin-left: -5px;
}
.col {
    
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
}
.c_pointer {
    cursor: pointer !important;
}
.text_reset {
    color: inherit!important;
}
.text_primary {
    color:#FD2C79 !important;
}
.fs_20 {
    font-size: 1.25rem !important;
}

.butn{
    border: none;
    display: block;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    overflow: hidden;
    position: relative;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    background-color: #222;
    padding: 17px 60px;
    margin: 0 auto;
    box-shadow: 0 5px 15px rgba(0,0,0,0.20);

}

  .butn span {
    position: relative; 
    z-index: 1;
  }
  
  .butn:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background: #78c7d2;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-98%) translateY(-25%) rotate(45deg);
  }
  
  .butn:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
  }
  .position{
    position: relative;
  }
  .my_badge {
    position: absolute;
    border-bottom: 30px solid #4d9c40;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    height: 0;
    width: 120px;
    text-align: center;
    transform: rotate(45deg);
    right: -40px;
    top: 5px;
    color: #fff;
    z-index: 10;
  }
  
  .my_badge::before {
    content: "";
    border-bottom: 11px solid #2d6824;
    border-left: 11px solid transparent;
    transform: rotate(45deg);
    left: -27px;
    top: 24px;
    position: absolute;
  }
  .my_badge::after {
    content: "";
    border-bottom: 11px solid #2d6824;
    border-left: 11px solid transparent;
    transform: rotate(45deg);
    left: 76px;
    top: 24px;
    position: absolute;
  }


@media (min-width: 768px) {
    .gutters_5{
        display: flex !important;
        padding-top: 20px;

    }
    .col_md_auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }
    .text_md_left {
        text-align: left !important;
    }
    .pt_md_0, .py_md_0 {
        padding-top: 0!important;
    }
   
    .md_rounded_0 {
        border-radius: 0 !important;
    }
    .h_md_250px, .size_md_250px {
        height: 250px;
    }
    .w_md_250px, .size_md_250px {
        width: 250px;
    }
    .pr_md_4, .px_md_4 {
        padding-right: 1.5rem!important;
    }
    
   
}
@media (max-width: 912px){
    .mw_100 {
        max-width: 90%!important;
    }
}
@media (max-width: 768px){
    .mb_5.row {
        display:block;
    }
    .col_8{
        width: 100% !important;
    }
    .col_4{
        width: 100% !important;
    }
    .size_150px{
        width: 150px;
        height: 150px;
      
    }
    .col_md_auto {
        padding-top: 35px !important;
    }
}
@media (max-width: 500px){
    .w_25 {
        width: 0!important;
    }
    .fs_12 {
        font-size: 10px !important;
    }
    .pb_1, .py_1 {
        padding-bottom: 1.25rem!important;
    }
}