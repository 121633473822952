.privacy-p {
  padding: 20px;
}
.privacy-p-paragraph {
  white-space: pre-line;
}
/* ------contect-------- */
.p-pre-line {
  white-space: pre-line;
}
.contect {
  background-color: #f1f1f1;
}
.contect-wrap {
  background-color: #ffffff;
  width: 95%;
  margin: auto;
  padding: 15px;
}
/* -----------------WideSearch---------------- */
.wide-s-btn {
  background-color: #2185d0;
  cursor: pointer;
  text-align: center;
  width: 86px !important;
  height: 36px;
  color: white;
  padding-top: 6px;
  border-radius: 5px;
  margin-left: 13px;
  margin-top: 8px !important;
}
