.display-n {
  display: none;
}
/* ----------------------------TOP BAR--------------------- */

.navbarI {
  height: 76px;
  /* background-color: #250507 !important; */
  background-color: #040e27 !important;
}

.navi-pic {
  margin-top: 5px;
  margin-left: 5px;
  height: 64px;
  width: 250px;
}

.heade {
  color: white;
  height: 64px;
  margin-left: 10px;
  display: inline;
  font-size: 12px;
  font-weight: 600;
  float: right;
  padding-top: 27px;
  font-family: "Rubik", sans-serif;
  margin-right: 22px;
}

.navi-txt {
  display: inline;
  margin-right: 15px;
  position: relative;
  transition: 250ms ease-in-out;
  cursor: pointer;
  padding-bottom: 3px;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
}

.navi-txt:hover {
  border-bottom: 2px solid white;
}

.menu-drop {
  border: 1px solid transparent;
  border-radius: 8px;
  position: absolute;
  margin-top: 30px;
  text-align: center;
  z-index: 9999;
  color: white;
  background-color: #172b4d;
  text-transform: uppercase;
  transition: 300ms ease-in-out;
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 14px;
  padding-bottom: unset;
  font-size: 12px;
}

.search-m-drop {
  margin-left: 30px;
}

.services-m-drop {
  margin-left: 125px;
}

.navi-ment-txt {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
  border-bottom: 2px solid transparent;
  text-align: center;
  white-space: nowrap;
}
.navi-ment-txt:hover {
  border-bottom: 2px solid white;
}
.bar-x {
  transform: scale(2.4);
}
.menu-prope {
  display: inline;
}
.menu-bar {
  display: none;
}
@media screen and (max-width: 800px) {
  .display-n {
    display: block;
  }
  .navi-txt:hover {
    border-bottom: 2px solid transparent;
  }

  .mbl-active-bdr:hover {
    border-bottom: 2px solid white !important;
  }
  .menu-prope {
    display: none;
  }
  .m-d-none {
    display: none;
  }
  .menu-prope {
    text-transform: uppercase;
    text-decoration: none;
    position: fixed;
    width: 100%;
    background: #172b4d;
    top: 90px;
    padding: 20px 50px;
    box-sizing: border-box;
    z-index: 9999;
    margin-top: -16px;
    margin-left: -10px;
  }
  .menu-bar {
    display: inline;
    float: right;
  }
  .navi-txt {
    display: block;
    margin-left: 20px;
  }
  .m-d-inline-prop {
    display: block !important;
    width: 170px !important;
    background-color: #000000;
    font-size: 15px;
    margin-top: -10px;
    border-radius: 10px;
    position: absolute;
    padding: 9px 0;
    margin-left: -215px;
    padding-right: 243px;
    animation: 100ms 1 slidein;
  }
}

@keyframes slidein {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0%;
  }
}
/* ----------------------------BOTTOM BAR--------------------- */
.bottom-bar {
  background-color: #040e27;
  color: white;
  padding: 20px;
  font-size: 15px;
  font-weight: 300;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.b-b-img {
  width: 138px !important;
  height: 138px;
  background-color: #80828823;
  margin-bottom: 25px;
  border-radius: 5px;
}
.adr-txt {
  padding: 2px;
  color: #aab1b7;
}
.Q-link {
  padding: 3px 0;
  margin-top: 10px !important;
  padding-bottom: unset;
  font-size: 20px;
}
.Q-link-ins {
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  color: #aab1b7;
  transition: all 300ms ease-in-out;
}
.Q-link-ins:hover {
  color: red;
}
.b-b-line {
  margin-top: 10px;
  height: 1.7px;
  width: 90% !important;
  margin: auto;
  background-color: #aab1b73a;
}
.cr-txrt {
  margin-top: 10px;
  padding: 10px;
  text-align: center;
}
.abt-company-txt {
  color: #aab1b7;
}

@media screen and (max-width: 768px) {
  .adr-txt {
    padding: 0px;
    color: #aab1b7;
  }
  .bottom-bar {
    text-align: center;
    margin: auto;
  }
  .Q-link-wrap {
    margin: auto;
    width: fit-content;
  }
  .Q-link {
    margin-bottom: 3px;
  }
  .Q-link-ins {
    padding: 0px 8px;
  }
}
