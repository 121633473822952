.pagination-css {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width:1100px){
  #a{order:1;}
  #b{order:2;}
  #c{order:3;}
  #d{order:4;}
  #e{order:5;}
  #f{order:6;}
  #g{order:7;}
  #h{order:8;}
  #i{order:9;}
  #j{order:10;}
  #k{order:11;}
  #l{order:12;}

}
