.view-users {
  border-radius: 5px;
  color: white;
  background-color: rgb(40, 163, 163);
  width: 9rem;
  height: 34px;
  border: none;
}
.fa-eye- {
  float: left;
  height: 34px;
  padding: 12px;
  background-color: rgb(17, 157, 157);
  border-radius: 5px 0px 0px 5px;
  margin-bottom: 10px;
  background: none;
}

/*------------------------------ top counts ----------------------------------------------------------*/

.dash{

    display: flex !important;
    margin-right: -20px;
    margin-left: -20px;
    padding-top: 20px;
}
.stretch-card {
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
}
.grid-margin, .purchase-popup {
  margin-bottom: 2.5rem;
}
.card.card-img-holder {
  position: relative;
}
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}
.card {
  border: 0;
  background: #fff;
}
.card {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3125rem;
}
.bg-gradient-danger {
  background: -webkit-gradient(linear, left top, right top, from(#ffbf96), to(#fe7096)) !important;
  background: linear-gradient(to right, #ffbf96, #fe7096) !important;
}
.bg-gradient-success {
  background: -webkit-gradient(linear, left top, right top, from(#84d9d2), to(#07cdae)) !important;
  background: linear-gradient(to right, #84d9d2, #07cdae) !important;
}
.bg-gradient-info {
  background: -webkit-gradient(linear, left top, right top, from(#90caf9), color-stop(99%, #047edf)) !important;
  background: linear-gradient(to right, #90caf9, #047edf 99%) !important;
}
.card .card-body {
  /* padding: 2.5rem 2.5rem; */
}
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  /* padding: 1.25rem; */
}
.card.card-img-holder .card-img-absolute {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.mb-3, .template-demo .circle-progress-block, .my-3 {
  margin-bottom: 1rem !important;
}
.text-white{
  color: #ffffff !important;
}
.h4 {
  font-size: 1.13rem;
}
a, div, h1, h2, h3, h4, h5, p, span {
  text-shadow: none;
}
.float-right {
  float: right !important;
}
.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}
h2, .h2 {
  font-size: 1.88rem;
}
h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "ubuntu-medium", sans-serif;
}
.card-text:last-child {
  margin-bottom: 0;
}
h6, .h6 {
  font-size: .9375rem;
}
.col-md-4s{
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}
@media (min-width: 768px){
.col-md-4s {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}}
@media (max-width: 768px){
  .col-md-4s {
    flex: 0 0 100%;
    max-width: 100%;
  }
.dash{
  display: block !important;
}}


/* search button css */
.wapsearch{
  padding-top: 20px;
  padding-bottom: 20px;
}

.searchbox{
  position: relative;
}
.search-top{
 display: flex;
 justify-content: space-between;
}

.searchbox .input{
  width: 0px;
  border: 0px;
  height: 45px;
  padding: 0px 23px;
  border-radius: 50px;
  box-shadow: 0 0 15px 1px #b6bbc0;
  outline: none;
  color: #42be11;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.5s linear;
}

.searchbtn{
  position: absolute;
  top: 0;
  right: -1px;
  background: #fff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s 0.6s linear;
}

.searchbtn .fas{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #42be11;
  font-size: 22px;
  transition: all 0.5s 0.6s linear;
}

/* jquery class */
.searchbtn.bg-green{
  background: #42be11;
  transition: all 0.2s linear;
}

.searchbtn .fas.color-white{
  color: #fff;
  transition: all 0.2s linear;
}

.searchbox .input.active-width{
  width: 275px;
}

.searchbtn:hover {
  background-color: #42be11;
  transition: all 0.2s linear;
  
}
.searchbox.input:hover .searchbox{
  background-color: #42be11;
  transition: all 0.2s linear;
}
.searchbox:hover .input{
  width: 275px;
}
.searchbtn:hover .fas{
  color: #fff;
  transition: all 0.2s linear;
}
@media (max-width: 768px){
  .search-top{
    display: block;
    /* justify-content: space-between; */
   }
  .searchbox .input{
    width: 100% !important;
  }
  }
