.match-data {
  border-bottom: 2px solid rgb(67, 73, 152);
}

.title-para {
  margin-top: 8px;
  font-weight: 600;
}
.data-para {
  font-weight: 400;
  color: black;
  font-size: 13px;
  margin-top: -15px;
  margin-bottom: 10px;
}
.star-data {
  margin-bottom: -1px;
  margin-left: -5px;
  margin-top: -6px;
  height: 25px;
}
.head-title {
  font-size: larger;
  font-weight: 700;
  text-align: center;
}
.main-div {
  margin-left: inherit !important;
}
.img-select {
  background-color: aquamarine;
  width: 50%;
}
.img-delete {
  background-color: azure;
  width: 50%;
}
.img-btn-div {
  display: flex;
}
.img-add {
  width: 50%;
  background-color: cadetblue;
}
