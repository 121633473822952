
.kattam-style-rasi {
    width: 220%;
    margin-top: -10px;
text-align: center;
    font-style: oblique;
    font-weight: 800;
}
.jathagam-align  {
    margin-left: 15%;
    background-color: white;
}
.kattam-style-navamsam {
    width: 220%;
    margin-top: -10px;
    text-align: center;
    font-style: oblique;
    font-weight: 800;
}

.printMantra {
    text-align: center;
    font-size: 12px;
}
.printMargin {
    margin-top: 15px;
    margin-bottom: 15px;
}

.printUnderlineDash {
    margin-top:5px;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
}

.printUnderline {
    margin-top:5px;
    margin-bottom:20px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.printBtn {
  width: 125px;
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  margin: 1px;
  padding: 10px 10px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.printBtn:hover {
    box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
    transform: translateY(-2px);
  }
  @media (min-width: 768px) {
    .printBtn:hover {
      padding: 10px 30px;
    }
  }

.whatsapp-btn {
  background-color: #13aa52;
  border: 1px solid #13aa52;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .1) 0 2px 4px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: "Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
  transform: translateY(0);
  transition: transform 150ms, box-shadow 150ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.whatsapp-btn:hover {
  box-shadow: rgba(0, 0, 0, .15) 0 3px 9px 0;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .whatsapp-btn {
    padding: 10px 30px;
  }
}