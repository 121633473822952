/* .container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7rem;
  padding: 0 2rem;
  height: fit-content;
  margin-bottom: 10px;
}

.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
}

.login-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: fit-content;
}

.img img {
  width: 500px;
}

.login-cont {
  width: 360px;
}

.login-content img {
  height: 100px;
}

.login-content h2 {
  margin: 15px 0;
  color: #333;
  text-transform: uppercase;
  font-size: 2.9rem;
}

.login-content .input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one {
  margin-top: 0;
}

.i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i i {
  transition: 0.3s;
}

.input-div > div {
  position: relative;
  height: 45px;
}

.input-div > div > p {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  transition: 0.3s;
  white-space: nowrap;
}

.input-div:before,
.input-div:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #526fb8;
  transition: 0.4s;
}

.input-div:before {
  right: 50%;
}

.input-div:after {
  left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
  width: 50%;
}

.input-div.focus > div > p {
  top: -5px;
  font-size: 15px;
}

.input-div.focus > .i > i {
  color: #38d39f;
}

.input-div > div > input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  color: #555;
  font-family: "poppins", sans-serif;
}

.input-div.pass {
  margin-bottom: 4px;
}

a {
  display: block;
  text-align: right;
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: 0.3s;
}

a:hover {
  color: #38d39f;
}

.btn-login {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-color: #040e27 !important;
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  transition: 0.5s;
  padding-top: 5px;
  margin-top: 28px;
}
.btn:hover {
  background-position: right;
}
.img img {
  max-height: 320px;
  object-fit: contain;
  margin-top: 10px;
}
@media screen and (max-width: 1050px) {
  .container {
    grid-gap: 5rem;
    height: fit-content;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .login-cont {
    width: 290px;
  }

  .login-content h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }

  .img img {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
    height: fit-content;
    margin-bottom: 10px;
  }

  .img {
    display: none;
  }

  .wave {
    display: none;
  }

  .login-content {
    justify-content: center;
    margin-left: unset;
  }
}
.login-condition-txt{
  color: red;
  white-space: nowrap;
  position: absolute;
  margin-top: 58px;    
  font-size: 12px;

} */
.wave{
	position: fixed;
	bottom: 0;
	left: 0;
	height: 50%;
	z-index: -1;
}
.login-cont {
  width: 360px;
  /* backdrop-filter: blur(10px); */
  padding: 19px;
  background-color: white;
  /* background-blend-mode: ; */
  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(10px);
  /* -webkit-backdrop-filter: blur(5px); */
}
.reset-cont {
  width: 360px;
  /* backdrop-filter: blur(10px); */
  padding: 19px;
  /* background-blend-mode: ; */
  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(10px);
  color: #fff;
  font-size:14px;
}
.reset-content {
  display: grid;
  justify-items: center;
  height: 100%;
  /* background-blend-mode: ; */

  /* -webkit-backdrop-filter: blur(5px); */
}
.background{
    background-image: url("/public/img/log_in_background.jpg");
    -webkit-background-size:cover;
    -moz-background-size: cover;
   -o-background-size: cover;
     background-size: cover;
}

.top{
  padding-top: 15% !important;
  padding-bottom: 15% !important;
}
.background.container{
  
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
   
    /* grid-template-columns: repeat(2, 1fr); */
    grid-gap :7rem;
    padding: 0 2rem;
}

.img{
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.login-content{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-align: center;
}

.img img{
	width: 500px;
}

/* form{
	width: 360px;
} */

.login-content img{
    height: 100px;
}

.login-content h2{
	margin: 15px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 2.9rem;
}

.login-content .input-div{
	position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one{
	margin-top: 0;
}

.i{
	color: #d9d9d9;
	display: flex;
	justify-content: center;
	align-items: center;
}

.i i{
	transition: .3s;
}

.input-div > div{
    position: relative;
	height: 45px;
}

.input-div > div > h5{
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: #999;
	font-size: 18px;
	transition: .3s;
}

.input-div:before, .input-div:after{
	content: '';
	position: absolute;
	bottom: -2px;
	width: 0%;
	height: 2px;
	background-color: #38d39f;
	transition: .4s;
}

.input-div:before{
	right: 50%;
}

.input-div:after{
	left: 50%;
}

.input-div.focus:before, .input-div.focus:after{
	width: 50%;
}

.input-div.focus > div > h5{
	top: -5px;
	font-size: 15px;
}

.input-div.focus > .i > i{
	color: #38d39f;
}

.input-div > div > input{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	background: none;
	padding: 0.5rem 0.7rem;
	font-size: 1.2rem;
	color: #555;
	font-family: 'poppins', sans-serif;
}

.input-div.pass{
	margin-bottom: 4px;
}

a{
	display: block;
	text-align: right;
	text-decoration: none;
	color: #999;
	font-size: 0.9rem;
	transition: .3s;
}

a:hover{
	color: #38d39f;
}

.login-content .loginBtn{
  border: none;
	display: block;
	width: 100%;
	height: 50px;
	border-radius: 25px;
	outline: none;
	border: none;
	background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
	background-size: 200%;
	font-size: 1.2rem;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase;
	margin: 1rem 0;
	cursor: pointer;
	transition: .5s;
}
.login-content .loginBtn:hover{
	background-position: right;
  outline-color: none !important;
  border: none;
}
.login-content .loginBtn.disabled{
  border: none !important;
}


@media screen and (max-width: 1050px){
	.background.container{
		grid-gap: 5rem;
	}
}

@media screen and (max-width: 1000px){
	
	.login-content h2{
        font-size: 2.4rem;
        margin: 8px 0;
	}

	.img img{
		width: 400px;
	}
}

@media screen and (max-width: 900px){
  .background{
    /* background-size: contain; */
  }
  .reset-cont {
    color: black;
  }
	.background.container{
		grid-template-columns: 1fr;
	}

	.img{
		display: none;
	}

	.wave{
		display: none;
	}

	.login-content{
		justify-content: center;
	}
}
@media screen and (max-width: 800px) {
  .background{
    background-image: none;
}
.background.container.container {
  height: 60vh !important;
  display: block;
  padding-top: 30px;
  
}
}