@media only screen and (max-width: 1000px) {
  .main-div {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 5px !important;
  }
}
.main-div {
  border-radius: 4px;
  width: max-content !important;
  height: fit-content !important;
  margin-left: 10px;
  margin-right: 10px;
}
.icon-style {
  width: 22px;
  padding-left: 4%;
  padding-top: 5%;
}
.btn-style {
  color: white;
  font-size: 15px;
  width: 120px;
  height: max-content;
  border-style: solid;
  border-width: 0px;
  border-end-end-radius: 4px;
  border-top-right-radius: 4px;
  /* height: 100% !important; */
}
.viewprofile-btn {
  background-color: darkgreen;
}
.sentintrest-btn {
  background-color: palevioletred;
}
.request-btn {
  background-color: palevioletred;

  cursor: none !important;
}
.accepted-btn {
  background-color: whitesmoke;
}
.print-btn {
  background-color: grey;
}
.accept-btn {
  background-color: rgb(39, 154, 255);
}
.viewprofile-main {
  background-color: aquamarine;
}
.sentintrest-main {
  background-color: lightpink;
}
.request-main {
  background-color: rgb(236, 116, 116);
}
.accept-main {
  background-color: whitesmoke;
}
.print-main {
  background-color: lightgray;
}
.accept-main {
  background-color: rgb(117, 171, 251);
}

.arrow-main {
  margin-top: -20px !important;
  display: flex;
  justify-content: flex-end;
  background-color: red;
}
.arrow-btn {
  background-color: rgb(237, 237, 173);
  width: 10%;
  margin-right: -10px !important;
}
.arrow {
  cursor: pointer;
  height: 120px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: transform 0.1s;
  width: 80px;
}
.arrow-top,
.arrow-bottom {
  background-color: #666;
  height: 1px;
  left: -5px;
  position: absolute;
  top: 50%;
  width: 20%;
}
.arrow-top:after,
.arrow-bottom:after {
  background-color: #fff;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.15s;
}
.arrow-top {
  transform: rotate(45deg);
  transform-origin: bottom right;
}
.arrow-top:after {
  left: 100%;
  right: 0;
  transition-delay: 0s;
}
.arrow-bottom {
  transform: rotate(-45deg);
  transform-origin: top right;
}
.arrow-bottom:after {
  left: 0;
  right: 100%;
  transition-delay: 0.15s;
}
.arrow:hover .arrow-top:after {
  left: 0;
  transition-delay: 0.15s;
}
.arrow:hover .arrow-bottom:after {
  right: 0;
  transition-delay: 0s;
}
.arrow:active {
  transform: translateX(-50%) translateY(-50%) scale(0.9);
}
