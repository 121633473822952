/* Popup style */
.popup-box {
  position: fixed;
  background: #35353550;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.condent-box {
  width: 160% !important;
  /* margin-left: -25%; */
  height: 80% !important;
}
@media only screen and (max-width: 800px) {
  .condent-box {
    width: 100% !important;
  }
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}
